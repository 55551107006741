<template>
  <section
    id="knowledge-base-content"
    class="px-4"
  >
    <!-- content -->
    <b-row class="kb-search-content-info match-height">
      <b-col
        md="3"
        sm="6"
        class="kb-search-content"
      >
        <b-card
          class="text-center cursor-pointer card-size"
          img-src="@/assets/images/activation.png"
          img-alt="tel"
          img-top
          @click="$router.push({ name: 'espace-admin.activateaccount' })"
        >
          <h5>ACTIVATION DE COMPTE</h5>
        </b-card>
      </b-col>
      <b-col
        md="3"
        sm="6"
        class="kb-search-content"
      >
        <b-card
          class="text-center cursor-pointer card-size"
          img-src="@/assets/images/devise.png"
          img-alt="tel"
          img-top
          @click="$router.push({ name: 'espace-admin.devise' })"
        >
          <h5>DEVISE</h5>
        </b-card>
      </b-col>
      <b-col
        md="3"
        sm="6"
        class="kb-search-content"
      >
        <b-card
          class="text-center cursor-pointer card-size"
          img-src="@/assets/images/doc.png"
          img-alt="tel"
          img-top
          @click="$router.push({ name: 'espace-admin.document' })"
        >
          <h5>DOCUMENTS</h5>
        </b-card>
      </b-col>
      <b-col
        md="3"
        sm="6"
        class="kb-search-content"
      >
        <b-card
          class="text-center cursor-pointer card-size"
          img-src="@/assets/images/map.png"
          img-alt="tel"
          img-top
          @click="$router.push({ name: 'espace-admin.pays' })"
        >
          <h5>DONNEES GEOGRAPHIQUES</h5>
        </b-card>
      </b-col>
      <b-col
        md="3"
        sm="6"
        class="kb-search-content"
      >
        <b-card
          class="text-center cursor-pointer card-size"
          img-src="@/assets/images/commentaire.png"
          img-alt="tel"
          img-top
          @click="$router.push({ name: 'espace-admin.comment' })"
        >
          <h5>COMMENTAIRES</h5>
        </b-card>
      </b-col>
      <b-col
        md="3"
        sm="6"
        class="kb-search-content"
      >
        <b-card
          class="text-center cursor-pointer card-size"
          img-src="@/assets/images/unit.webp"
          img-alt="tel"
          img-top
          @click="$router.push({ name: 'espace-admin.unittype' })"
        >
          <h5>UNITES DE MESURES</h5>
        </b-card>
      </b-col>
      <b-col
        md="3"
        sm="6"
        class="kb-search-content"
      >
        <b-card
          class="text-center cursor-pointer card-size"
          img-src="@/assets/images/camions.png"
          img-alt="tel"
          img-top
          @click="$router.push({ name: 'espace-admin.transport' })"
        >
          <h5>MODELES DE CAMIONS</h5>
        </b-card>
      </b-col>
      <b-col
        md="3"
        sm="6"
        class="kb-search-content"
      >
        <b-card
          class="text-center cursor-pointer card-size"
          img-src="@/assets/images/goods.jpg"
          img-alt="tel"
          img-top
          @click="$router.push({ name: 'espace-admin.marchandise' })"
        >
          <h5>MARCHANDISES</h5>
        </b-card>
      </b-col>
      <b-col
        md="3"
        sm="6"
        class="kb-search-content"
      >
        <b-card
          class="text-center cursor-pointer card-size"
          img-src="@/assets/images/stats.png"
          img-alt="tel"
          img-top
          @click="$router.push({ name: 'espace-admin.statistiques' })"
        >
          <h5>STATISTIQUES</h5>
        </b-card>
      </b-col>
      <b-col
        md="3"
        sm="6"
        class="kb-search-content"
      >
        <b-card
          class="text-center cursor-pointer card-size"
          img-src="@/assets/images/illustration/sales.svg"
          img-alt="tel"
          img-top
          @click="$router.push({ name: 'espace-admin.activateaccount' })"
        >
          <h5>GESTION DES UTILISATEURS</h5>
        </b-card>
      </b-col>
      <b-col
        md="3"
        sm="6"
        class="kb-search-content"
      >
        <b-card
          class="text-center cursor-pointer card-size"
          img-src="@/assets/images/illustration/sales.svg"
          img-alt="tel"
          img-top
          @click="$router.push({ name: 'espace-admin.offers' })"
        >
          <h5>VALIDATION DES OFFRES</h5>
        </b-card>
      </b-col>
    </b-row>
  </section>

</template>

<script>
import Ripple from 'vue-ripple-directive'

import {
  BRow, BCol, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BCard,
  },
  directives: {
    Ripple,
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';

.card-img-top {
    width: 100%;
    height: 15vw;
    object-fit: cover;
}
</style>
